import React from "react";
import Logo from "resources/images/logo-quince-white.svg";
import styled from "styled-components";
import { colors } from "resources/Theme";
import Bg from "resources/images/inactive-header.png";
import LinkButton from "components/LinkButton";

const Container = styled.div`
  width: min(640px, 100%);
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 75px auto auto;
  background: ${colors.primary};
`;

const Header = styled.div`
  width: min(640px, 100%);
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 122px;
    height: 48px;
  }
`;

const Body = styled.div`
  width: 100%;
  margin: 0 auto 50px;
`;

const Footer = styled.div`
  font-size: 12px;
  color: ${colors.white};
  text-align: left;
  width: min(576px, 90%);
  margin: 0 auto;
  padding: 20px 0 30px;
  font-weight: 300;
  line-height: 0.8rem;
`;
const InfoSection = styled.div`
  text-align: center;
  color: ${colors.white};
  font-size: 16px;
  width: 95%;
  margin: 0 auto 0;

  p {
    margin: 30px auto 0;
  }
`;

const Mail = ({
  children,
  buttonText,
  link,
  disabled,
  show = false,
  isPreview = false,
}) => (
  <Container>
    <Header>
      <img src={Logo} alt="Logo" />
    </Header>

    {!isPreview && (
      <InfoSection>
        <p>
          Hello, the digital menu has some items marked with &nbsp;
          <strong>86 Inactive</strong>, please check if it is correct.
        </p>
      </InfoSection>
    )}

    <Body> {children} </Body>

    {show && !isPreview && (
      <>
        <InfoSection>
          If is not correct, please go to Manager Site to solve.
        </InfoSection>
        <LinkButton text={buttonText} link={link} disabled={disabled} />
      </>
    )}

    {!isPreview && (
      <Footer>
        The content of this email is confidential and intended for the recipient
        specified in message only. It is strictly forbidden to share any part of
        this message with any third party, without a written consent of the
        sender. If you received this message by mistake, please reply to this
        message and follow with its deletion, so that we can ensure such a
        mistake does not occur in the future.
      </Footer>
    )}
  </Container>
);

export default Mail;
